//
// General Styles
// --------------------------------------------------

html {
	margin: 0;
	padding: 0;
	text-size-adjust: 100%;
	font-size: $text-base-size;
	overflow-x: hidden !important;
	scroll-behavior: smooth;
}

body {
	font-family: $font-base;
	font-size: $text-base-size;
	line-height: $text-base-line-height;
	color: $color-text;
	background-color: #ffffff;
	margin: 0;
	padding: 0;
	scroll-behavior: smooth;
	overflow-x: hidden !important;
	@media (max-width:1199px) {
		font-size: 15px;
	}
}

* {
	box-sizing: border-box;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

iframe {
	max-width: 100%;
}

a img,
img {
	border: none;
	max-width: 100%;
	height: auto;
}

a {
	color: $color-link;
	cursor: pointer;
	text-decoration: none;
	transition: $transition-base;

	&:hover {
		outline: none;
		color: $color-link-hover;
		text-decoration: none;

		i {
			color: inherit;
		}
	}

	&:focus {
		outline: none;
		text-decoration: none;
	}
}

.training-add-to-cart {
	&.loading {
		&:before {
			content: '';
			width: 30px;
			height: 30px;
			background-image: url('../images/ajax-loader.svg');
			background-size: 100%;
			background-position: center;
			background-repeat: no-repeat;
			position: absolute;
			right: -25px;
			top: 50%;
			transform: translateY(-50%);
		}
	}
}

p,
blockquote {
	margin-bottom: 20px;
	margin-top: 0;

	&:last-child {
		margin-bottom: 0;
	}
}

blockquote {
	border-left: 5px solid $color-gray-lighter;
	padding: 10px 0 10px 25px;
}

ul {
	margin-top: 25px;
	margin-bottom: 30px;
	list-style-position: inside;
	padding: 0;
}

h1 {
	font-size: 8.8rem;
	color: $color-primary;
	font-family: $font-serif;
	font-weight: 700;
	line-height: $title-base-line-height;

	@media (max-width:1199px) {
		font-size: 50px;
	}
}

h2,
.h2 {
	font-size: 7rem;
	color: $color-green-light;
	font-family: $font-serif;
	font-weight: 700;
	line-height: $title-base-line-height;

	@media (max-width:1199px) {
		font-size: 46px;
		line-height: 1.1;
	}

}

h3,
.h3 {
	font-size: 2.9rem;
	color: $color-text;
	font-family: $font-base;
	font-weight: 700;
	line-height: $title-base-line-height;
	@media (max-width:1199px) {
		font-size: 30px;
	}
}

h4,
.h4 {
	font-size: 2rem;
	color: $color-text;
	font-family: $font-base;
	font-weight: 700;
	line-height: $title-base-line-height;

	@media (max-width:1199px) {
		font-size: 24px;
	}
}

h5,
.h5 {
	font-size: 1.5rem;
	color: $color-text;
	font-family: $font-base;
	font-weight: 500;
	line-height: $title-base-line-height;
	@media (max-width:1199px) {
		font-size: 22px;
	}
}

h6,
.h6 {
	font-size: 1.3rem;
	color: $color-text;
	font-family: $font-base;
	font-weight: 500;
	line-height: $title-base-line-height;
	@media (max-width:1199px) {
		font-size: 17px;
	}
}

table {
	margin-top: $margin-base;
	margin-bottom: 15px;
	border: inherit;
	border-color: inherit;
	border-spacing: 0;
	border-collapse: collapse;

	tr {
		border: inherit;
		border-color: inherit;

		td {
			border-top: 1px solid $color-gray-lighter !important;
			padding: 1rem !important;
			vertical-align: middle !important;
		}
	}
}

.accordion .content table {
	margin-top: 30px;
	max-width: 100%;
	tr, td {
		display: block;
		width: 100%;
	}
}

address {
	font-style: normal;
}

.gm-style {
	// .gm-ui-hover-effect {
	// 	display: none;
	// 	visibility: hidden;
	// }

	.gm-style-iw-c {
		border-radius: 0px !important;
		border: 2px solid $color-primary !important;
		background-color: white !important;
		color: $color-text !important;
		padding: 20px 25px !important;
		min-width: 345px !important;
		max-width: 400px !important;
	}

	.gm-style-iw-d {
		max-height: initial;
		overflow: visible !important;

		// span {
		// 	color: $color-text;
		// 	margin-bottom: 10px;
		// 	display: inline-block;
		// 	font-weight: 600;
		// }

		.content {
			font-size: 1.1rem !important;
			line-height: 1.5 !important;
			font-weight: 500 !important;
			width: 100% !important;
			font-family: $font-base !important;

			address {
				margin: 0 !important;
			}
		}
	}

	.gm-style-iw-t {
		&::after {
			border: 2px solid $color-primary !important;
			border-top: none !important;
			border-right: none !important;
		}
	}
}

.btn-primary,
.wp-element-button,
input[type="submit"],
button,
button[type="submit"],
.product .cart .single_add_to_cart_button,
#error-template .button,
.woocommerce a.button {
	font-weight: 500 !important;
	color: $color-text !important;
	text-align: center;
	border: 2px solid $color-primary !important;
	border-radius: 0 !important;
	padding: 10px 20px !important;
	vertical-align: middle;
	background-color: white !important;
	transition: $transition-base;
	width: fit-content;
	cursor: pointer;

	&:hover {
		background-color: $color-primary !important;
		color: white !important;
	}

	&.success {
		background-color: $color-green;
		color: $color-text;

		&:hover {
			color: $color-text;
			background-color: lighten($color-green, 5%);
		}
	}

	&.error {
		background-color: $color-red;
		color: $color-text;

		&:hover {
			color: $color-text;
			background-color: lighten($color-red, 5%);
		}
	}
}

.alert {
	padding: 15px 20px 15px 55px;
	background: rgba(210, 224, 214, 0.16);
	border: 2px solid #D2E0D6;
	box-shadow: 2px 2px 6px rgba(143, 143, 143, 0.24);
	border-radius: 3px;
	position: relative;
	margin-top: 40px;
	width: 830px;
	max-width: 100%;
	&:before {
		display: block;
		content: '\f05a';
		font-family: 'Font Awesome 6 Pro';
		font-size: 30px;
		color: #D2E0D6;
		position: absolute;
		left: 12px;
		top: 50%;
		transform: translateY(-50%);
	}
}

.btn-secondary {
	position: relative;
	font-size: 0.95rem;
	font-weight: 500;
	color: $color-link;
	padding: 0 2px;

	&::after {
		content: "";
		width: 100%;
		height: 2px;
		background-color: $color-primary;
		position: absolute;
		left: 0;
		bottom: -6px;
		transform-origin: 0% 50%;
		transform: scaleX(1);
		transition: transform 0.2s linear;
	}

	&:hover {
		&::after {
			transform: scaleX(0);
			transform-origin: 100% 50%;
		}
	}

	&.w-icon {
		color: $color-text;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		width: fit-content;

		&::before {
			font-family: "Font Awesome 6 Pro";
			font-weight: 400;
			color: inherit;
			margin-right: 10px;
			vertical-align: middle;
		}

		&:hover {
			color: $color-primary;
		}
	}

	&.phone {
		&::before {
			content: "\f095";
			transform: rotateY(180deg);
		}
	}

	&.mail {
		&::before {
			content: "\f0e0";
		}
	}

	&.external {
		&::before {
			content: "\f35d";
		}
	}

	&.file {
		&::before {
			content: "\f33d";
		}
	}

	&.infos {
		&::before {
			content: "\f30f";
		}
	}
}

.btn-more {
	background-color: transparent;
	padding: 0;
	color: $color-link;
	border: none;
	vertical-align: middle;
	display: flex;

	i {
		margin-right: 10px;
		vertical-align: middle;
	}

	&:hover {
		background-color: transparent;
		color: $color-link-hover;
	}
}

.select2 {
	width: 100% !important;
	height: 100%;
    display: flex;
	.selection {
		width: 100%;
    	display: flex;
		.select2-selection {
			width: 100%;
			align-items: center;
    		display: flex;
		}
	}
}

.select2-results__options {
	li:first-child {
		display: none;
	}
}

.select2-dropdown {
	border-color: $color-primary;
	border-radius: 0;
}

.select2-results__option {
	padding: 8px 20px;
	background-color: white;
	color: $color-text;
	font-size: 18px;
	transition: $transition-base;

	&.select2-results__option--highlighted {
		background-color: white !important;
		color: $color-text !important;
	}

	&:hover {
		background-color: lighten($color-primary, 20%) !important;
		color: $color-text !important;
	}

	&.select2-results__option--selected {
		background-color: $color-primary !important;
		color: $color-text !important;
	}
}

.select2 {
	&.select2-container {
		height: 68px;
		@media (max-width:1199px) {
			height: 46px;
		}
	}
	&.select2-container--open {
		.select2-selection__rendered {
			color: $color-gray-dark !important;
		}

		.selection {
			.select2-selection--single {
				.select2-selection__arrow {
					transform: rotate(180deg) translateY(50%);
				}
			}
		}
	}

	.select2-selection__arrow {
		top: 50% !important;
		right: 15px !important;
		transform: translateY(-50%);
		width: auto !important;
		height: auto !important;
		transition: $transition-base;

		&::after {
			content: "\f107";
			font-family: "Font Awesome 6 Pro";
			color: $color-text;
			font-size: 22px;
		}
	}

	.select2-selection__arrow b {
		display: none;
		visibility: hidden;
	}
}

.select2-selection--single {
	background-color: transparent !important;
	height: auto !important;
	border-radius: 0px !important;
	border: none !important;
	border-bottom: 2px solid $color-primary !important;
	color: $color-text;

	.select2-selection__choice {
		background-color: $color-primary !important;
		color: $color-text !important;
		border-color: $color-primary !important;
		vertical-align: middle !important;
		margin: 6px !important;
		padding: 0 !important;
	}

	.select2-selection__choice__remove {
		color: $color-text !important;
		border-color: $color-text !important;
		vertical-align: middle !important;
		padding: 5px 8px !important;
		position: static !important;

		&:hover {
			border-right: 1px solid $color-text !important;
			background-color: darken($color-primary, 10%) !important;
		}
	}

	.select2-selection__choice__display {
		padding: 0 6px !important;
	}
}

.select2-selection__rendered {
	padding: 15px 20px !important;
	font-size: 18px;
	line-height: normal !important;
	color: $color-gray-darker !important;
	transition: all 0.2s linear;
	width: 100%;
}

.gform_validation_errors {
	padding: 0;
	color: $color-red;
	margin-bottom: 25px;
	font-size: 14px;

	.gform_submission_error {
		color: $color-red;
		font-size: 24px;
		line-height: normal;
		margin-bottom: 10px;
	}

	ol {
		padding: 0;
		list-style-position: inside;
	}

	.gform_validation_error_link {
		color: $color-red;
	}
}

.validation_message {
	color: $color-red;
	clear: both;
}

#ui-datepicker-div {
	background-color: white;
	box-shadow: 1px 1px 8px 1px rgba(0,0,0,0.3);
    padding: 10px;
	max-width: 100vw;
    overflow: scroll;
	.ui-datepicker-next,
	.ui-datepicker-prev {
		display: none;
	}
	.ui-datepicker-calendar {
	    margin-top: 10px;
	}
}

form,
.form {
	.gform_body {
		display: table;
		width: 100%;
	}

	.gform_footer {
		margin-top: 20px;
		display: flex;
		align-items: flex-start;
		justify-content: flex-end;
	}

	.gform_hidden {
		display: none !important;
	}

	.gfield_required {
		margin-left: 5px;
		color: $color-red;
		font-weight: bold;
		transition: all 0.2s linear;
		visibility: hidden;
	}

	.gform_fields {
		margin: 0;
		padding: 0;
		list-style: none;
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		column-gap: 30px;
		row-gap: 30px;

		@media (max-width:1199px) {
			display: flex;
			flex-flow: column;
		}

		.uploader-btn {
			display: flex;
			align-items: center;
			justify-content: flex-start;

			.uploader-filename {
				margin-right: 60px;
				min-width: 150px;
			}

			button {
				background-color: $color-primary;
				color: $color-text;
				border: 2px solid $color-primary;
				box-shadow: none;

				&:hover {
					background-color: $color-link-hover;
					color: $color-text;
					border: 2px solid $color-primary !important;
				}

				&:focus {
					border: 2px solid $color-link-hover !important;
				}
			}
		}

		.gfield_list_group {
			.gfield_list_icons {
				margin-top: 10px;
			}
		}

		.gfield {
			margin: 0;
			padding: 0;
			position: relative;
			font-size: 18px;
			line-height: normal;

			&.hide {
				display: none;
			}

			&.gfield--width-full {
				grid-column: span 2;
			}

			&.small {
				input {
					width: calc(50% - 15px);
				}
			}

			&.gfield--type-fileupload {

				.gform_fileupload_multifile {
					padding: 0 20px;

					.gform_button_select_files {
						padding: 0 !important;
						border: none !important;
						color: $color-primary !important;
						font-size: 18px !important;

						&:hover {
							color: $color-link-hover !important;
							background-color: transparent !important;
						}
					}
				}

				.ginput_preview_list {
					margin: 20px 0;

					.ginput_preview {
						display: flex;
						align-items: center;
						padding: 12px 0;
					}

					.gfield_fileupload_filesize {
						margin-left: auto;
					}

					.gfield_fileupload_filesize,
					.gfield_fileupload_progress {
						font-size: .8rem;
						color: $color-primary;
						text-transform: uppercase;
						padding: 0 10px;
						white-space: nowrap;
					}

					.gform_delete_file {
						padding: 0 !important;
						color: $color-primary !important;
						border: none !important;

						&:hover {
							background-color: transparent !important;
							color: $color-text !important;
						}
					}
				}
			}

			&.flex {
				.gfield_radio {
					display: flex;
					align-items: center;
					justify-content: flex-start;

					.gchoice {
						margin-right: 1.5rem;
					}
				}
			}

			&.grid {
				.gfield_radio {
					display: grid;
					grid-template-columns: repeat(2, 1fr);
					column-gap: 0.6rem;
					row-gap: 0.6rem;
				}
			}

			&.gfield_visibility_hidden {
				display: none;
			}

			&.hidden_label {
				.gfield_label {
					display: none;
				}
			}

			&:focus-within,
			&.not-empty {
				label,
				.gfield_label {
					transform: translateY(-1.1em);
					font-size: 0.8em;
					color: $color-primary;
					z-index: 1;
					@media (max-width:1199px) {
						font-size: 10px!important;
					}
				}

				.gfield_required {
					color: transparent;
				}
			}
			&.label-top {
				.gfield_label {
					position: static;
					margin-bottom: 15px;
					display: block;
					transform: inherit;
					font-size: inherit;
					color: inherit;
				}
			}

			&.field_sublabel_above {
				margin-bottom: 20px;
			}

			&.file-input {
				display: none;
			}

			&.gfield_error {
				input,
				textarea,
				select,
				.select2 .select2-selection,
				.ginput_container_fileupload,
				.ginput_container_radio label::before,
				.ginput_container_checkbox label::before {
					border: 2px solid $color-red !important;
				}
			}

			.title {
				margin-top: 30px !important;
				margin-bottom: 0 !important;
			}

			// .gfield_label_before_complex {
			// 	display: none;
			// }

			.ginput_container_date {
				position: relative;

				.ui-datepicker-trigger {
					position: absolute;
					right: 0.9rem;
					top: 50%;
					transform: translateY(-50%);
				}
			}

			.ginput_container_select {
				height: 100%;
			}

			.ginput_container_fileupload {
				display: flex;
				width: 100%;
			    border: 2px solid $color-primary;
			    border-radius: 4px;
				cursor: pointer;
				height: 65px;
				align-items: center;
				input {
					display: none;
				}
				&:before {
					display: block;
					content: 'Choisir un fichier';
					width: 165px;
					background: rgba($color-primary, 0.4);
					height: 100%;
					align-items: center;
					display: flex;
					justify-content: center;
					padding: 10px;
					border-right: 2px solid $color-primary;
					font-size: 14px;
				}
				&:after {
					display: block;
					content: 'Aucun fichier choisi';
					height: 100%;
					align-items: center;
					display: flex;
					padding: 10px;
					color: #A8A8A8;
					font-size: 14px;
				}
				&[data-before] {
					&:after {
						content: attr(data-before);
					}
				}
				.gform_drop_area {
					display: none;
				}
			}

			&.multiple-files {
				.ginput_container_fileupload {
					&:before {
						content: 'Choisir des fichiers';
					}
				}
			}

			.ginput_complex {
				display: inline-block;
				width: 100%;

				span {
					display: inline-block;
					width: 100%;

					&.name_first,
					&.ginput_left {
						width: 50%;
						padding-right: 15px;
						float: left;
					}

					&.name_last,
					&.ginput_right {
						width: 50%;
						padding-left: 15px;
						float: left;
					}

					&.address_line_1 {
						margin-bottom: 20px;
					}
				}
			}

			.gchoice {
				transition: all 0.2s linear;
				display: flex;
    			align-items: center;

				@media (max-width:1199px) {
					align-items: flex-start;
				}

				&:not(:last-child) {
					margin-bottom: 4px;
				}

				input {
					visibility: hidden;
					margin: 0;
					margin-right: 0;
					display: none;
				}

				label {
					display: flex;
					align-items: center;
					font-weight: 400;
					position: relative;
					cursor: pointer;
					left: initial;
					top: initial;
					transition: all 0.2s linear;

					@media (max-width:1199px) {
						margin: 0 0 10px 0;
					}

					&::before {
						content: '';
                        display: inline-block;
                        width: 25px;
                        min-width: 25px;
                        height: 25px;
                        min-height: 25px;
                        border-radius: 100%;
                        margin-right: 15px;
                        border: 2px solid rgba(54, 180, 87, 0.5);
                        cursor: pointer;
					}
				}

				.gfield-choice-input:checked ~ label {
					font-weight: 600;

					&::before {
						border-color: rgba(54, 180, 87, 0.5);
						background-color: rgba(54, 180, 87, 0.5);
					}
				}

				// &:hover {
				// 	label {
				// 		&::before {
				// 			background-color: $color-primary;
				// 		}
				// 	}
				// }
			}

			.gfield_checkbox {
				.gchoice {
					label {
						display: block;
						align-items: center;
						font-weight: 400;
						position: relative;
						cursor: pointer;
						left: initial;
						top: initial;
						transition: all 0.2s linear;
						&::before {
							content: '';
	                        display: inline-block;
	                        width: 25px;
	                        min-width: 25px;
	                        height: 25px;
	                        min-height: 25px;
	                        margin-right: 15px;
	                        border: 2px solid rgba(54, 180, 87, 0.5);
							border-radius: 0;
	                        cursor: pointer;
							position: relative;
							top: 6px;
						}
					}
				}
			}
		}
	}

	label,
	.gfield_label {
		font-size: inherit;
		line-height: inherit;
		color: $color-gray-darker;
		position: absolute;
		left: 0.9rem;
		top: 1.4rem;
		transition: all 0.2s linear;
		transform-origin: left top;

		@media (max-width:1199px) {
			top: 0;
			bottom: 0;
			font-size: 15px;
		}
	}

	.input-group-addon {
		background-color: $color-gray-darker;
		color: $color-primary;
		border: 0;
	}

	fieldset {
		border: none;

		legend {
			position: static !important;
			margin-bottom: 0.6rem;
			font-weight: 600;
		}
	}

	select {
		padding: 8px 10px;
		background-color: white;
		height: 42px;
		color: $color-gray;
		width: 100%;
		border: 0;
		border: 1px solid $color-gray-lighter;
	}

	select[multiple] {
		height: auto;
	}

	input,
	textarea {
		width: 100%;
		border: 2px solid #9ad9ab;
		color: $color-gray-darker;
		padding: 1.4rem 0.9rem;
		appearance: none;
		resize: none;
		background-color: transparent;
		font-size: 1rem;
		line-height: normal;
		font-family: inherit;

		@media (max-width:1199px) {
			padding: 13px 10px;
		}

		&::placeholder {
			font-size: inherit;
			color: transparent;
		}

		&:focus {
			outline: none !important;
			box-shadow: none !important;
			border: 2px solid $color-primary !important;

			&::placeholder {
				color: inherit;
				opacity: 0.3;
			}
		}
	}

	input[type="checkbox"],
	input[type="radio"] {
		width: auto;
		appearance: inherit;
	}

	input[type="checkbox"] {
		appearance: checkbox;
	}

	input[type="radio"] {
		appearance: radio;
	}

	.gfield_description {
		margin-top: 0;
		margin-bottom: 10px;
		font-size: 0.8rem;
		font-style: italic;

		&.gform_fileupload_rules {
			padding: 0 20px;
		}
	}
}

.container,
.container-fluid {
	position: relative;
}

.wow {
	visibility: hidden;
}

.slick-list {
	overflow: hidden;

	.slick-slide {
		overflow: hidden;

		&:focus {
			outline: none;
		}
	}
}

.slick-slider {
	visibility: hidden;
	margin-bottom: 0;
}

.slick-initialized {
	visibility: visible !important;
}

.slick-arrow {
	z-index: 1;
	height: auto;
	width: auto;

	&:before {
		font-family: "Font Awesome 6 Pro";
		color: $color-primary;
		font-size: 1.8rem;
		font-weight: bold;
	}

	&.slick-prev {
		left: 0;

		&:before {
			content: "\f053";
		}
	}

	&.slick-next {
		right: 0;

		&:before {
			content: "\f054";
		}
	}
}

.slick-dots {
	bottom: 15px;

	li {
		width: auto !important;
		height: auto !important;
		margin: 0 5px !important;
		display: block !important;
		float: left !important;

		button {
			width: 12px !important;
			height: 12px !important;
			padding: 0 !important;
			margin: 0 !important;
			background-color: #ffffff !important;
			border-radius: 100% !important;
			opacity: 0.7 !important;
			padding: 0 !important;

			&:before,
			&:after {
				display: none !important;
			}
		}

		&:first-child {
			margin-left: 0 !important;
		}

		&:last-child {
			margin-right: 0 !important;
		}

		&.slick-active {
			button {
				opacity: 1 !important;
			}
		}

		&:only-child {
			display: none !important;
		}
	}
}

.screen-reader-text {
	clip: rect(1px, 1px, 1px, 1px);
	position: absolute !important;
	height: 1px;
	width: 1px;
	overflow: hidden;
}

.page-section {
	padding-top: $margin-base;
	padding-bottom: $margin-base;
}

.no-padding-top {
	padding-top: 0;
}

.no-padding-bottom {
	padding-bottom: 0;
}

.no-margin-top {
	margin-top: 0;
}

.no-margin-bottom {
	margin-bottom: 0;
}

#main-content {
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		&:first-child {
			margin-top: 0;
		}
	}
}

.acf-map {
	width: 100%;
	height: 700px;
	border: 0;
	border: 0;
}

.highlight {
	color: #ffffff;
	background-color: $color-primary;
}

#error-template {
	.error-details {
		margin-bottom: 30px;
	}

	.error-actions {
		.button {
			&:hover {
				i {
					color: #ffffff;
				}
			}
		}
	}
}

#post-share {
	.social-share {
		padding: 0;
		margin: 0;
		list-style: none;

		li {
			display: inline-block;
			margin-right: 30px;

			&:last-child {
				margin-right: 0;
			}

			a {
				i {
					font-size: 25px;
				}
			}
		}
	}
}

.loading-spinner {
	width: 50px;
	height: 50px;
	margin: 0 auto;
	float: none;
	background-image: url("../images/ajax-loader.svg");
	background-size: 100%;
	background-position: center;
	background-repeat: no-repeat;
}

#pagination {
	text-align: center;

	.navigation {
		ul {
			padding: 0;
			margin: 0;

			li {
				display: inline-block;

				a {
					color: #ffffff;
					text-decoration: none;
					background-color: $color-gray-dark;
					cursor: pointer;
					padding: 5px 13px;
					border-radius: 5px;
					display: block;

					&:hover {
						background-color: $color-primary;
					}
				}

				&.active {
					a {
						background-color: $color-primary;
					}
				}
			}
		}
	}
}

#cookie-notice {
	.button {
		font-size: 14px;
		padding: 5px 10px;
	}
}

/* WordPress */
.wp-caption,
.gallery-caption {
	color: #666;
	font-size: 13px;
	font-size: 0.8125rem;
	font-style: italic;
	margin-bottom: 1.5em;
	max-width: 100%;
}

.wp-caption img[class*="wp-image-"] {
	display: block;
	margin-left: auto;
	margin-right: auto;
}

.wp-caption .wp-caption-text {
	margin: 0.8075em 0;
	font-size: 14px;
}

.bypostauthor > .comment-body > .comment-meta > .comment-author .avatar {
	border: 1px solid #333;
	padding: 2px;
}

.alignleft {
	display: inline;
	float: left;
	margin-right: 1.5em;
}

.alignright {
	display: inline;
	float: right;
	margin-left: 1.5em;
}

.aligncenter {
	clear: both;
	display: block;
	margin-left: auto;
	margin-right: auto;
}

img.alignleft {
	float: left;
	margin-right: 1.5em;
}

img.alignright {
	float: right;
	margin-left: 1.5em;
}


.ginput_container_radio{
	@media (max-width:1199px) {
		margin-top: 10px;
	}
}
