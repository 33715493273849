//
// Responsive Styles
// --------------------------------------------------

@media (min-width: 1199px) {

	.container {
		max-width: 1170px;
	}

}

@media (min-width: 1441px) {

	.container {
		max-width: 1440px;
	}

	// #main {

	// 	#single-news {

	// 		#related-posts {
	// 			max-width: 99%;
	// 			margin-left: calc(1% + 15px);
	// 		}
	// 	}
	// }
}

@media (max-width: 1199px) {

	#header {
		#menu-mobile-trigger {
			display: block;
		}
	}

}

@media (max-width: 991px) {

	#custom-checkout {
		#custom-checkout-content {
			#payment {
				.payment {
					.payment-blocs {
						.label {
							font-size: 18px;
						    margin-right: 20px;
						}
					}
				}
			}
		}
	}

}

@media (max-width: 767px) {



}

@media (max-width: 576px) {

	#custom-checkout {
		#custom-checkout-tabs {
			.checkout-tab {
			    padding: 15px 10px;
				.tab-content {
					.step {
						font-size: 16px;
					}
					.name {
						font-size: 18px;
					}
				}
			}
		}
		form {
			.gform_fields {
				.gfield {
					.ginput_container_fileupload {
						&:before {
							width: 115px;
							font-size: 14px;
							text-align: center;
							padding: 15px;
						}
						&:after {
						    padding: 15px;
						}
					}
				}
			}
		}

		#custom-checkout-content {
			.radio-inline {
				.gfield_radio {
					display: block !important;
					align-items: center;
					.gchoice {
						&:not(:last-child) {
							margin-right: 0 !important;
						}
					}
				}
			}
			.checkbox-inline {
				.gfield_checkbox {
					display: block !important;
					.gchoice {
						&:not(:last-child) {
							margin-right: 0 !important;
						}
					}
				}
			}
			#payment {
				.payment {
					margin-top: 40px;
				    padding: 25px 15px;
					.payment-blocs {
						display: block;
						&.last {
							margin-top: 30px;
						}
						.label {
							width: 100%;
							margin-right: 15px;
							margin-bottom: 5px;
						}
						.value {
							width: fit-content
						}
					}
				}
				.wc_payment_methods {
					display: block;
					.wc_payment_method {
						margin: 0;
						&:not(:last-child) {
							margin-bottom: 10px;
						}
					}
				}
			}
		}

	}

	form {
		.gform_fields {
			.gfield {
				font-size: 16px;
			}
		}
	}

}
