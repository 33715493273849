//
// Header Styles
// --------------------------------------------------

#header {
	width: 100%;
	background-color: white;
	padding-top: 60px;
	padding-bottom: 35px;
	position: fixed;
	left: 0;
	top: 0;
	z-index: 20;
	transition: all 0.4s linear;
	@media (max-width: 1199px) {
		.container{
			padding: 0 10px;
		}
	}

	// &.dark {
	// 	background-color: transparent;
	// 	color: white;

	// 	&.sticky {
	// 		background-color: white;

	// 		#menu {

	// 			#menu-main-container {

	// 				ul {

	// 					li {

	// 						a {
	// 							color: $color-text;
	// 						}

	// 						&:hover {

	// 							a {
	// 								color: white;
	// 							}
	// 						}
	// 					}
	// 				}
	// 			}
	// 		}
	// 	}

	// 	#menu {

	// 		#menu-main-container {

	// 			ul {

	// 				li {

	// 					a {
	// 						color: white;
	// 					}
	// 				}
	// 			}
	// 		}
	// 	}
	// }

	&.sticky {
		padding: 20px 0;
		#header-extras {
			opacity: 0;
			margin-top: -40px;
		}

		#logo {
			max-width: 300px;

			a {

				// svg {
				// 	display: none;
				// 	visibility: hidden;
				// 	&.sticky-logo {
				// 		display: initial;
				// 		visibility: visible;
				// 	}
				// }
			}

			@media (max-width:1441px) {
				max-width: 230px;
			}
		}
	}

	#menu-mobile-trigger {
		font-size: 31px;
		display: none;
		.trigger-bar {
			display: block;
			width: 25px;
			height: 2px;
			transition: all 0.2s linear;
			position: relative;
			background-color: white;
			&:not(:first-child) {
				margin-top: 6px;
			}
		}
	}

	#logo {
		width: 100%;
		max-width: 320px;
		transition: $transition-base;
		a {
			display: block;
			width: 100%;
			height: auto;

			svg {
				width: 100%;
				height: auto;

				&.sticky-logo {
					display: none;
					visibility: hidden;
				}
			}
		}

		@media (max-width:1441px) {
			max-width: 250px;
		}
	}

	#search-action {
		.input-group {
			display: flex;
			.form-control {
				width: 250px;
			}
		}
	}

	#header-content {
		display: flex;
    align-items: center;
    justify-content: space-between;
	}

	#menu {
		transition: all 0.4s linear;
		#menu-main-container {
			display: inline-block;
			width: 100%;
			ul {
				margin: 0;
				padding: 0;
				list-style: none;
				text-align: right;
				float: right;
				li {
					float: left;
					position: relative;
					margin-right: 30px;
					border: 2px solid $color-primary;
					background-color: transparent;
					transition: $transition-base;
					a {
						font-size: 1.18rem;
						font-weight: 500;
						display: block;
						color: $color-text;
						padding: 10px 20px;
					}
					&:last-child {
						margin-right: 0;
					}
					&:hover {
						background-color: $color-primary;
						a {
							color: white;
						}
					}
					&.current-menu-item,
					&.current-menu-ancestor,
					&.current-menu-parent,
					&.current-product-ancestor,
					&.current-product_cat-ancestor,
					&.current-page-ancestor {
						background-color: $color-primary;
						> a {
							color: white;
						}
						.sub-menu {
							li {
								a {
									color: $color-text;
								}
							}
						}
					}
					.sub-menu {
						opacity: 0;
						height: 0;
						padding: 0;
						position: absolute;
						text-align: left;
						margin-left: -2px;
					  	transform: translateY(20px);
						transition: transform 0.25s ease-in-out, opacity 0.25s ease-in-out;
						z-index: 10;
						overflow: hidden;
						li {
							white-space: nowrap;
							margin: 0;
							float: none;
							padding: 0 10px;
							background-color: $color-primary;
							a {
								display: block;
								color: white !important;
								background-color: $color-primary;
								padding: 20px 10px;
								min-width: 250px;
								position: relative;
								font-size: 1rem;
								&::before {
									content: "";
									width: 100%;
									height: 2px;
									background-color: rgba(255, 255, 255, 0.5);
									position: absolute;
									left: 0;
									bottom: -2px;
								}
							}
							&:last-child {
								a {

									&::before {
										display: none;
									}
								}
							}
							&:hover {
								background-color: $color-link-hover;
								border-color: $color-link-hover;
								> a {
									background-color: $color-link-hover;
								}
							}
							&.current-menu-item,
							&.current-menu-ancestor,
							&.current-menu-parent,
							&.current-product-ancestor,
							&.current-product_cat-ancestor,
							&.current-page-ancestor {
								background-color: $color-link-hover;
								border-color: $color-link-hover;

								a {
									background-color: $color-link-hover;
									color: white;
								}
							}
							.sub-menu {
								opacity: 0;
								height: 0;
								padding: 0;
								position: absolute;
								right: -2px;
								top: -4px;
								text-align: left;
								margin-left: -2px;
							  	transform: translateX(0) translateY(20px);
								transition: transform 0.25s ease-in-out, opacity 0.25s ease-in-out;
								z-index: 10;
								overflow: hidden;
							}
						}
					}
					&.menu-item-has-children {
						> a {
							position: relative;
							&:after {
								font-family: "Font Awesome 6 Pro";
								content: '\f107';
								display: inline-block;
								transform-origin: center;
								color: inherit;
								transition: transform 0.25s;
								margin-left: 10px;
								font-size: 16px;
								vertical-align: middle;
								line-height: 1em;
								transform: translateY(-2px) rotate(0deg);
							}
						}
						&:hover {
							> a {
								&:after {
									color: white;
									transform: translateY(-2px) rotate(180deg);
								}
							}
							> .sub-menu {
								height: auto;
								opacity: 1;
								transform: translateY(0);
								padding-top: 2px;
								overflow: visible;
								.sub-menu {
									transform: translateX(100%) translateY(0);
								}
							}
						}
					}
				}
			}
		}
	}

	#header-extras {
		position: absolute;
		top: -60px;
		right: 15px;
		transition: all 0.4s linear;
		margin-top: 0;
		display: flex;
    align-items: flex-start;
    justify-content: flex-end;

		.extra {
			display: inline-block;
			border-right: 1px solid white;

			&:last-child {
				border-right: none;
			}

			a {
				padding: 15px 20px;
				color: white;
				background-color: $color-primary;
				font-weight: 500;
				font-size: 0.9rem;
				display: block;

				i {
					font-weight: 400;
				}
			}

			&:hover {

				a {
					background-color: $color-link-hover;

					i {
						color: inherit;
					}
				}
			}
		}

		#header-cart {
			i {
				color: #ffffff;
				margin-right: 6px;
			}
		}

		#language-switcher {
			.languages-list {
				margin: 0;
				padding: 0;
				list-style: none;
				li {
					display: inline-block;
					border-right: 1px solid $color-primary;
					margin-right: 7px;
					padding-right: 7px;
					.lang {
						text-transform: uppercase;
						display: block;
						color: $color-primary;
						&.active {
							color: #ffffff;
							font-weight: lighter;
						}
					}
					&:last-child {
						border-right: 0;
						margin-right: 0;
						padding-right: 0;
					}
				}
			}
		}
	}
}
