//
// Font Faces Styles
// @help: url('../webfonts/Muller/../webfonts/Muller/xxx)
// --------------------------------------------------

@font-face {
    font-family: 'Muller';
    src: url('../webfonts/Muller/Muller-Light.woff2') format('woff2'),
        url('../webfonts/Muller/Muller-Light.woff') format('woff'),
        url('../webfonts/Muller/Muller-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Muller';
    src: url('../webfonts/Muller/Muller-MediumItalic.woff2') format('woff2'),
        url('../webfonts/Muller/Muller-MediumItalic.woff') format('woff'),
        url('../webfonts/Muller/Muller-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Muller';
    src: url('../webfonts/Muller/Muller-ExtraBoldItalic.woff2') format('woff2'),
        url('../webfonts/Muller/Muller-ExtraBoldItalic.woff') format('woff'),
        url('../webfonts/Muller/Muller-ExtraBoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Muller';
    src: url('../webfonts/Muller/Muller-HeavyItalic.woff2') format('woff2'),
        url('../webfonts/Muller/Muller-HeavyItalic.woff') format('woff'),
        url('../webfonts/Muller/Muller-HeavyItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Muller Hairline';
    src: url('../webfonts/Muller/Muller-HairlineItalic.woff2') format('woff2'),
        url('../webfonts/Muller/Muller-HairlineItalic.woff') format('woff'),
        url('../webfonts/Muller/Muller-HairlineItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Muller';
    src: url('../webfonts/Muller/Muller-Heavy.woff2') format('woff2'),
        url('../webfonts/Muller/Muller-Heavy.woff') format('woff'),
        url('../webfonts/Muller/Muller-Heavy.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Muller Hairline';
    src: url('../webfonts/Muller/Muller-Hairline.woff2') format('woff2'),
        url('../webfonts/Muller/Muller-Hairline.woff') format('woff'),
        url('../webfonts/Muller/Muller-Hairline.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Muller';
    src: url('../webfonts/Muller/Muller-BlackItalic.woff2') format('woff2'),
        url('../webfonts/Muller/Muller-BlackItalic.woff') format('woff'),
        url('../webfonts/Muller/Muller-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Muller';
    src: url('../webfonts/Muller/Muller-Black.woff2') format('woff2'),
        url('../webfonts/Muller/Muller-Black.woff') format('woff'),
        url('../webfonts/Muller/Muller-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Muller Fat';
    src: url('../webfonts/Muller/Muller-Fat.woff2') format('woff2'),
        url('../webfonts/Muller/Muller-Fat.woff') format('woff'),
        url('../webfonts/Muller/Muller-Fat.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Muller';
    src: url('../webfonts/Muller/Muller-ExtraBold.woff2') format('woff2'),
        url('../webfonts/Muller/Muller-ExtraBold.woff') format('woff'),
        url('../webfonts/Muller/Muller-ExtraBold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Muller';
    src: url('../webfonts/Muller/Muller-LightItalic.woff2') format('woff2'),
        url('../webfonts/Muller/Muller-LightItalic.woff') format('woff'),
        url('../webfonts/Muller/Muller-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Muller';
    src: url('../webfonts/Muller/Muller-Regular.woff2') format('woff2'),
        url('../webfonts/Muller/Muller-Regular.woff') format('woff'),
        url('../webfonts/Muller/Muller-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Muller';
    src: url('../webfonts/Muller/Muller-Bold.woff2') format('woff2'),
        url('../webfonts/Muller/Muller-Bold.woff') format('woff'),
        url('../webfonts/Muller/Muller-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Muller';
    src: url('../webfonts/Muller/Muller-Medium.woff2') format('woff2'),
        url('../webfonts/Muller/Muller-Medium.woff') format('woff'),
        url('../webfonts/Muller/Muller-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Muller';
    src: url('../webfonts/Muller/Muller-BoldItalic.woff2') format('woff2'),
        url('../webfonts/Muller/Muller-BoldItalic.woff') format('woff'),
        url('../webfonts/Muller/Muller-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Muller';
    src: url('../webfonts/Muller/Muller-RegularItalic.woff2') format('woff2'),
        url('../webfonts/Muller/Muller-RegularItalic.woff') format('woff'),
        url('../webfonts/Muller/Muller-RegularItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Muller Fat';
    src: url('../webfonts/Muller/Muller-FatItalic.woff2') format('woff2'),
        url('../webfonts/Muller/Muller-FatItalic.woff') format('woff'),
        url('../webfonts/Muller/Muller-FatItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Muller UltraBlack';
    src: url('../webfonts/Muller/Muller-UltraBlack.woff2') format('woff2'),
        url('../webfonts/Muller/Muller-UltraBlack.woff') format('woff'),
        url('../webfonts/Muller/Muller-UltraBlack.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Muller';
    src: url('../webfonts/Muller/Muller-UltraLightItalic.woff2') format('woff2'),
        url('../webfonts/Muller/Muller-UltraLightItalic.woff') format('woff'),
        url('../webfonts/Muller/Muller-UltraLightItalic.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Muller Narrow';
    src: url('../webfonts/Muller/MullerNarrow-BoldItalic.woff2') format('woff2'),
        url('../webfonts/Muller/MullerNarrow-BoldItalic.woff') format('woff'),
        url('../webfonts/Muller/MullerNarrow-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Muller Narrow';
    src: url('../webfonts/Muller/MullerNarrow-BlackItalic.woff2') format('woff2'),
        url('../webfonts/Muller/MullerNarrow-BlackItalic.woff') format('woff'),
        url('../webfonts/Muller/MullerNarrow-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Muller';
    src: url('../webfonts/Muller/Muller-ThinItalic.woff2') format('woff2'),
        url('../webfonts/Muller/Muller-ThinItalic.woff') format('woff'),
        url('../webfonts/Muller/Muller-ThinItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Muller Narrow';
    src: url('../webfonts/Muller/MullerNarrow-Black.woff2') format('woff2'),
        url('../webfonts/Muller/MullerNarrow-Black.woff') format('woff'),
        url('../webfonts/Muller/MullerNarrow-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Muller';
    src: url('../webfonts/Muller/Muller-Thin.woff2') format('woff2'),
        url('../webfonts/Muller/Muller-Thin.woff') format('woff'),
        url('../webfonts/Muller/Muller-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Muller';
    src: url('../webfonts/Muller/Muller-UltraLight.woff2') format('woff2'),
        url('../webfonts/Muller/Muller-UltraLight.woff') format('woff'),
        url('../webfonts/Muller/Muller-UltraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Muller UltraBlack';
    src: url('../webfonts/Muller/Muller-UltraBlackItalic.woff2') format('woff2'),
        url('../webfonts/Muller/Muller-UltraBlackItalic.woff') format('woff'),
        url('../webfonts/Muller/Muller-UltraBlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Muller Narrow';
    src: url('../webfonts/Muller/MullerNarrow-ExtraBoldItalic.woff2') format('woff2'),
        url('../webfonts/Muller/MullerNarrow-ExtraBoldItalic.woff') format('woff'),
        url('../webfonts/Muller/MullerNarrow-ExtraBoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Muller Narrow';
    src: url('../webfonts/Muller/MullerNarrow-Bold.woff2') format('woff2'),
        url('../webfonts/Muller/MullerNarrow-Bold.woff') format('woff'),
        url('../webfonts/Muller/MullerNarrow-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Muller Narrow';
    src: url('../webfonts/Muller/MullerNarrow-ExtraBold.woff2') format('woff2'),
        url('../webfonts/Muller/MullerNarrow-ExtraBold.woff') format('woff'),
        url('../webfonts/Muller/MullerNarrow-ExtraBold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Muller Narrow';
    src: url('../webfonts/Muller/MullerNarrow-Medium.woff2') format('woff2'),
        url('../webfonts/Muller/MullerNarrow-Medium.woff') format('woff'),
        url('../webfonts/Muller/MullerNarrow-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Muller Narrow';
    src: url('../webfonts/Muller/MullerNarrow-UltraLightItalic.woff2') format('woff2'),
        url('../webfonts/Muller/MullerNarrow-UltraLightItalic.woff') format('woff'),
        url('../webfonts/Muller/MullerNarrow-UltraLightItalic.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Muller Narrow';
    src: url('../webfonts/Muller/MullerNarrow-MediumItalic.woff2') format('woff2'),
        url('../webfonts/Muller/MullerNarrow-MediumItalic.woff') format('woff'),
        url('../webfonts/Muller/MullerNarrow-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Muller Narrow';
    src: url('../webfonts/Muller/MullerNarrow-ThinItalic.woff2') format('woff2'),
        url('../webfonts/Muller/MullerNarrow-ThinItalic.woff') format('woff'),
        url('../webfonts/Muller/MullerNarrow-ThinItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Muller Narrow';
    src: url('../webfonts/Muller/MullerNarrow-Light.woff2') format('woff2'),
        url('../webfonts/Muller/MullerNarrow-Light.woff') format('woff'),
        url('../webfonts/Muller/MullerNarrow-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Muller Narrow';
    src: url('../webfonts/Muller/MullerNarrow-Thin.woff2') format('woff2'),
        url('../webfonts/Muller/MullerNarrow-Thin.woff') format('woff'),
        url('../webfonts/Muller/MullerNarrow-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Muller Narrow';
    src: url('../webfonts/Muller/MullerNarrow-Regular.woff2') format('woff2'),
        url('../webfonts/Muller/MullerNarrow-Regular.woff') format('woff'),
        url('../webfonts/Muller/MullerNarrow-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Muller Narrow';
    src: url('../webfonts/Muller/MullerNarrow-LightItalic.woff2') format('woff2'),
        url('../webfonts/Muller/MullerNarrow-LightItalic.woff') format('woff'),
        url('../webfonts/Muller/MullerNarrow-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Muller Narrow';
    src: url('../webfonts/Muller/MullerNarrow-UltraLight.woff2') format('woff2'),
        url('../webfonts/Muller/MullerNarrow-UltraLight.woff') format('woff'),
        url('../webfonts/Muller/MullerNarrow-UltraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Muller Narrow';
    src: url('../webfonts/Muller/MullerNarrow-RegularItalic.woff2') format('woff2'),
        url('../webfonts/Muller/MullerNarrow-RegularItalic.woff') format('woff'),
        url('../webfonts/Muller/MullerNarrow-RegularItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Muller Narrow Hairline';
    src: url('../webfonts/Muller/MullerNarrow-Hairline.woff2') format('woff2'),
        url('../webfonts/Muller/MullerNarrow-Hairline.woff') format('woff'),
        url('../webfonts/Muller/MullerNarrow-Hairline.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Muller Narrow';
    src: url('../webfonts/Muller/MullerNarrow-Heavy.woff2') format('woff2'),
        url('../webfonts/Muller/MullerNarrow-Heavy.woff') format('woff'),
        url('../webfonts/Muller/MullerNarrow-Heavy.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Muller Narrow';
    src: url('../webfonts/Muller/MullerNarrow-HeavyItalic.woff2') format('woff2'),
        url('../webfonts/Muller/MullerNarrow-HeavyItalic.woff') format('woff'),
        url('../webfonts/Muller/MullerNarrow-HeavyItalic.ttf') format('truetype');
    font-weight: 800;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Muller Narrow Hairline';
    src: url('../webfonts/Muller/MullerNarrow-HairlineItalic.woff2') format('woff2'),
        url('../webfonts/Muller/MullerNarrow-HairlineItalic.woff') format('woff'),
        url('../webfonts/Muller/MullerNarrow-HairlineItalic.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}
