//
// WooCommerce Styles
// --------------------------------------------------

.woocommerce {
    .woocommerce-form {
        position: relative;
        border: none !important;
        margin: 0 !important;

        .form-row {
            position: relative;
            margin-bottom: 10px;
            &.active {
                label {
					transform: translateY(-1.1em);
					font-size: 0.8em;
					color: $color-primary;
                    z-index: 1;
					@media (max-width:1199px) {
						font-size: 10px!important;
					}
				}
            }
        }

        &.woocommerce-form-login {
            .woocommerce-form-login__submit {
                float: right !important;
                margin: 0 !important;
            }
        }

    }
    .single_add_to_cart_button,
    #place_order,
    .checkout-button {
        background-color: $color-link !important;
        border-radius: 0 !important;
        &:hover {
            background-color: $color-link-hover !important;
        }
        &:focus {
            outline: none;
        }
    }
    .related.products {
        margin-top: $margin-base;
    }
    .onsale {
        background-color: $color-primary !important;
    }
    .woocommerce-info {
        font-size: 14px;
        border-top: 0;
        padding: 16px 14px;
        border-radius: 5px;
        &:before {
            display: none;
        }
    }
    .widget_price_filter {
        .ui-slider {
            .ui-slider-range {
                background-color: $color-primary;
            }
            .ui-slider-handle {
                background-color: $color-primary;
            }
        }
        .price_slider_wrapper {
            .ui-widget-content {
                background-color: #ebe9eb;
            }
        }
    }
    .product {
        .summary {
            .cart {
                .quantity {
                    .input-text {
                        padding: 5px;
                    }
                }
            }
            .product_meta {
                span {
                    display: block;
                    span {
                        display: inline;
                    }
                }
            }
        }
    }
    .cart-collaterals {
        margin-top: $margin-base;
    }
    .woocommerce-pagination {
    	.page-numbers {
    		border: 0 !important;
    		li {
    			display: inline-block !important;
    			border-right: 0 !important;
    			float: none !important;
    			a, span {
    				color: white !important;
    				text-decoration: none !important;
    				background-color: $color-gray-dark !important;
    				cursor: pointer !important;
    				padding: 5px 13px !important;
    				display: block !important;
    				font-size: 14px !important;
    				line-height: inherit !important;
    				&:hover {
    					background-color: $color-primary !important;
    					color: #ffffff !important;
    				}
    				&.current {
    					background-color: $color-primary !important;
    					color: #ffffff !important;
    				}
    			}
    		}
    	}
    }
}

#custom-checkout {
    position: relative;
    &.processing {
        &:before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: rgba(255, 255, 255, 0.5);
            z-index: 1;
            cursor: progress;
        }
    }
    .back {
        margin-bottom: 5px;
        display: inline-block;
    }
    #custom-checkout-tabs,
    #custom-checkout-tabs-modules {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        .checkout-tab,
        .checkout-tab-module {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            width: 100%;
            cursor: pointer;
            transition: all 0.05s linear;
            width: 220px;
            max-width: 100%;
            position: relative;
            padding: 25px 20px;
            background-color: rgba(54, 180, 87, 0.08);

            @media (max-width:1199px) {
                width: calc(50% - 5px);
                margin: 5px 0;
            }

            &:nth-child(odd){
                @media (max-width:1199px) {
                    margin-right: 10px!important;
                }
            }

            &:not(:last-child) {
                margin-right: 15px;
                @media (max-width:1199px) {
                    margin-right: inherit;
                }
            }


            .tab-content {
                width: 100%;
                .step {
                    font-size: 17px;
                }
                .name {
                    font-weight: bold;
                    font-size: 22px;
                    line-height: 1.2em;
                }
            }
            &.active {
                opacity: 1;
                color: white;
                background-color: $color-primary;
                &:after {
                    z-index: 1;
                    border-top-right-radius: 3px;
                    border-bottom-right-radius: 3px;
                    width: calc(100% + 3px);
                }
            }
        }
    }
    #modules-warning {
        margin-bottom: 50px;
    }
    .form-module {
        border-bottom: 3px solid rgba(54, 180, 87, 0.2);
        margin-top: 60px;
        // &.linked {
        //     padding-left: 50px;
        //     margin-top: 40px;
        //     .form-title {
        //         font-size: 22px;
        //         margin-bottom: 25px;
        //     }
        // }
        .form-title {
            position: relative;
            padding-right: 45px;
            padding-left: 50px;
            margin-bottom: 35px;
            font-size: 28px;
            cursor: pointer;
            &:after {
                content: '\f077';
                display: block;
                position: absolute;
                top: calc(50% - 12px);
                right: 0;
                font-family: 'Font Awesome 6 Pro';
                font-style: normal;
                font-weight: 900;
                font-size: 24px;
                line-height: 24px;
                color: $color-primary;
                transition: all 0.2s ease-out;
                transform-origin: center;
            }
            &:before {
                content: '\f14a';
                display: block;
                position: absolute;
                top: calc(50% - 16px);
                left: 0;
                font-family: 'Font Awesome 6 Pro';
                font-style: normal;
                font-size: 32px;
                line-height: 32px;
                color: $color-primary;
                font-weight: 300;
                color: #cacaca;
            }
        }
        .registration-table {
            display: none;
            margin-bottom: 25px;
        }
        &.active {
            .registration-table {
                display: block;
            }
            .form-title {
                &:after {
                    transform: rotate(180deg);
                }
            }
        }
        &.selected {
            .form-title {
                &:before {
                    font-weight: 600;
                    color: $color-primary;
                }
            }
        }
    }
    #custom-checkout-tabs-modules {
        margin: 0 0 15px 0;
        .checkout-tab-module {
            border-radius: 4px;
            padding: 10px;
            min-width: 130px;
            width: 130px;
            background: none;
            border: 4px dashed rgba(54, 180, 87, 0.2);
            margin-bottom: 15px;
            .tab-content {
                .name {
                    text-align: center;
                    font-size: 16px;
                }
            }
        }
    }
    #custom-checkout-content {
        margin-top: 45px;
        @media (max-width:1199px) {
            margin-top: 25px;
        }
        .alert.alert-display {
            display: none;
            margin: 0 0 30px;
            &:before {
                top: 26px;
            }
        }
        .alert.alert-message {
            margin: 0 0 30px;
            &:before {
                top: 50%;
                transform: translateY(-50%);
            }
            border-color: rgba(red, 0.3);
            box-shadow: inherit;
            background: rgba(red, 0.2);
            &:before {
                color: rgba(red, 0.7);
            }
        }
        #checkout-slots {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            .slot {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                &:not(:last-child) {
                    margin-right: 50px;
                }
                .slot-label {
                    margin-right: 20px;
                }
                .slot-input {
                    input {
                        width: 80px;
                        padding-top: 15px;
                        padding-bottom: 15px;
                        text-align: center !important;
                    }
                }
            }
        }
        .step-content {
            display: none;
            // display: block !important;
            &.active {
                display: block;
            }
        }
        #customer_details,
        .extra-fields {
            display: none;
            // display: block !important;
            label {
                position: static !important;
                visibility: visible !important;
                display: block !important;
            }
            .woocommerce-billing-fields,
            .woocommerce-shipping-fields,
            .woocommerce-additional-fields {
                display: none;
            }
        }
        .radios {
            &.flex {
                display: flex;
                align-items: center;
                .radio {
                    margin-top: 0;
                }
            }
            .value {
                font-size: 16px;
                font-weight: bold;
                @media (max-width:1199px) {
                    font-size: 15px;
                }
            }
            .radio {
                font-size: 17px;
                display: flex;
                align-items: center;
                margin-top: 10px;
                cursor: pointer;

                @media (max-width:1199px) {
                    font-size: 15px;
                }

                &:not(:last-child) {
                    margin-bottom: 20px;
                }
                input {
                    display: none;
                }
                &:before {
                    content: '';
                    display: inline-block;
                    width: 25px;
                    min-width: 25px;
                    height: 25px;
                    min-height: 25px;
                    border-radius: 100%;
                    margin-right: 15px;
                    border: 2px solid rgba(54, 180, 87, 0.5);
                    cursor: pointer;
                }
                &.active {
                    &:before {
                        border-color: rgba(54, 180, 87, 0.5);
                        background-color: rgba(54, 180, 87, 0.5);
                    }
                    .other {
                        display: block;
                    }
                }
                &.checkbox {
                    &:before {
                        border-radius: 0%;
                    }
                }
                &.error {
                    &:before {
                        border-color: red !important;
                    }
                }
            }
            &#term-checkbox,
            &#requis-checkbox {
                padding: 0;
                margin: 0;
                .checkbox {
                    text-transform: inherit;
                    display: inline-block;
                }
            }
            &#term-checkbox {
                margin: 0 0 15px 0;
            }
        }
        .next-step-container {
            display: flex;
            margin-top: 30px;
            .next-step {
                margin-left: auto;
            }
        }
        .radio-inline {
            .gfield_radio {
                display: flex;
                align-items: center;
                .gchoice {
                    &:not(:last-child) {
                        margin-right: 75px;
                    }
                }
            }
        }
        .checkbox-inline {
            .gfield_checkbox {
                display: flex;
                align-items: center;
                .gchoice {
                    &:not(:last-child) {
                        margin-right: 75px;
                    }
                }
            }
        }
        .gform_footer {
            display: none;
        }
        .gform_fileupload_rules {
            display: none;
        }
        #pre-registration-warning {
            display: none;
        }
        .woocommerce-checkout-review-order-table {
            display: none;
        }
        #payment {
            background: none;
            label {
                position: static !important;
                display: inline-block !important;
                visibility: visible !important;
            }
            .payment {
                margin-top: 50px;
                margin-bottom: 30px;
                background: rgba(210, 224, 214, 0.16);
                border-radius: 3px;
                padding: 55px 30px;
                border-radius: 5px;
                .place-order {
                    margin: 30px 0 0;
                    .woocommerce-terms-and-conditions-wrapper {
                        margin: 0;
                        padding: 0;
                        .form-row {
                            padding: 0;
                            margin: 0;
                            .woocommerce-form__label  {
                                margin: 0;
                                position: inherit;
                                left: inherit;
                                right: inherit;
                                top: inherit;
                                bottom: inherit;
                            }
                        }
                    }
                }
                .text-payment {
                    font-size: 14px;
                    margin: 40px 0 0 0;
                }
                .payments-title {
                    font-weight: 500;
                    font-size: 22px;
                    margin: 40px 0px 20px 0;
                }
                .payment-blocs {
                    display: flex;
                    align-items: center;
                    .label {
                        font-size: 22px;
                        font-weight: 500;
                        margin-right: 25px;
                    }
                    .value {
                        font-size: 17px;
                        font-weight: 400;
                        position: relative;
                        &:after {
                            display: block;
                            content: '';
                            width: calc(100% + 10px);
                            height: 1px;
                            background-color: black;
                            position: absolute;
                            bottom: -2px;
                            left: 50%;
                            transform: translateX(-50%);
                        }
                    }
                }
                .payment-blocs-description {
                    margin: 25px 0 20px 0;
                }
            }
            .title {
                font-size: 15px;
            }
            .payment_box {
                padding: 0;
                margin: 0;
                background: no-repeat;
                p {
                    display: none !important;
                }
                &:before {
                    display: none !important;
                }
            }
            .woocommerce-terms-and-conditions-wrapper {
                margin-top: 50px;
                @media (max-width:1199px) {
                    margin-top: 25px;
                    margin-bottom: 25px;
                }
            }
            .wc_payment_methods {
                padding: 0;
                border: 0;
                display: flex;
                align-items: center;
                margin-bottom: 20px;
                flex-wrap: wrap;
                .wc_payment_method {
                    display: flex;
                    align-items: center;
                    margin-right: 60px;
                    margin-bottom: 30px;
                    label {
                        position: static !important;
                        font-weight: normal;
                        margin: 0 !important;
                        line-height: 1.2em;
                    }
                    input {
                        display: none;
                    }
                    &:before {
                        content: '';
                        display: inline-block;
                        width: 25px;
                        min-width: 25px;
                        height: 25px;
                        min-height: 25px;
                        border-radius: 100%;
                        margin-right: 15px;
                        border: 2px solid rgba(54, 180, 87, 0.5);
                        cursor: pointer;
                    }
                    &.active {
                        &:before {
                            border-color: rgba(54, 180, 87, 0.5);
                            background-color: rgba(54, 180, 87, 0.5);
                        }
                    }
                }
            }
            .place-order {
                padding: 0;
            }
        }
        .gfield_description {
            display: none;
        }
    }
    form {
        #field_4_21,
        #field_4_20,
        #field_4_15,
        #field_5_11,
        #field_5_15,
        #field_7_21,
        #field_7_22,
        #field_7_1,
        #field_8_11,
        #field_8_15,
        #field_7_36,
        #field_6_72 {
            .gfield_label {
                transform: translateY(-1.5em);
                font-size: .8em;
                color: #36b457;
            }
        }
        @media (min-width: 1200px) {
            #field_4_20,
            #field_7_21,
            #field_7_22,
            #field_8_15,
            #field_5_15,
            #field_8_18,
            #field_5_18 {
                width: calc(50% - 15px);
            }
        }
        .gfield_required {
            display: none;
        }
        .extra-fields {
            padding: 20px;
            background-color: #fdc8c8;
            border-radius: 10px;
            margin: 30px 0;
        }
        .form-section-title {
            margin: 30px 0 0 0;
        }
    }
    .form-title {
        &.secondary {
            margin-top: 25px;
            margin-bottom: 15px;
        }
    }
    #step-4 {
        .form-title {
            margin-top: 50px;
        }
    }
}

.registration-table {
    .table {
        margin: 0;
        width: 100%;
        text-align: left;
        th {
            padding: 1rem !important;
        }
        .session {
            .input {
                input {
                    display: none;
                }
                &:after {
                    content: '';
                    display: inline-block;
                    width: 25px;
                    min-width: 25px;
                    height: 25px;
                    min-height: 25px;
                    border-radius: 100%;
                    margin-right: 15px;
                    border: 2px solid rgba(54, 180, 87, 0.5);
                    cursor: pointer;
                }
            }
            &.active {
                .input {
                    &:after {
                        border-color: rgba(54, 180, 87, 0.5);
                        background-color: rgba(54, 180, 87, 0.5);
                    }
                }
            }
        }
    }
}

.wc-item-meta {
    margin-top: 0;
}

.woocommerce ul.order_details li {
    padding-right: 1.5em !important;
    margin-right: 1.5em !important;
    margin-bottom: 1.5em !important;
    &:last-child {
        padding-right: 0px !important;
        margin-right: 0px !important;
    }
}

.woocommerce .blockUI.blockOverlay {
    position: fixed;
    background: rgba(255, 255, 255, 0.75) !important;
    opacity: 0.5;
}
