//
// Footer Styles
// --------------------------------------------------

%afterbefore{
	content: " ";
	position: absolute;
}

#footer {
	background-color: transparent;
	color: $color-text;
	font-size: 1rem;
	margin-top: 9rem;
	position: relative;

	@media (max-width: 992px) {
		font-size: 15px;
	}

	&::before{
		@extend %afterbefore;
		left: 0;
		top: 54px;
		width: 129px;
		height: 129px;
		background: #ED3224;
		border-radius: 50%;
		z-index: -1;
		@media (max-width: 992px) {
			width: 83px;
			height: 83px;
			top: -83px;
		}
	}

	#footer-top {
		position: relative;
		overflow: hidden;
		color: white;
		font-weight: 500;
		min-height: 350px;

		&::after{
			@extend %afterbefore;
			left: 0;
			top: 0;
			width: 1234px;
			height: 1234px;
			background: #36B457;
			border-radius: 50%;
			z-index: -1;
			margin: 0 auto 0 50%;
			transform: translate(-50%, 0);
			@media (max-width: 992px) {
				width: 1155px;
				height: 1155px;
			}
		}


		a {
			color: white;
		}

		.container {
			position: absolute;
			left: 50%;
			top: 7rem;
			transform: translateX(-50%);
			@media (max-width:992px) {
				position: inherit;
				left: inherit;
				top: inherit;
				transform: none;
				max-width: 250px;
				padding-top: 65px;
				padding-bottom: 30px;
			}
		}

		.row {
			display: grid;
			grid-template-columns: auto auto;
			column-gap: 4rem;
			align-items: flex-start;
			justify-content: center;
			@media (max-width:992px) {
				display: flex;
				flex-flow: column;
				margin: 0;
			}
		}

		address {

			span {
				color: white;
				margin-bottom: 0.3rem;
				display: block;
			}
		}

		.eduqua {
			width: 125px;
			margin-top: 3rem;
    		display: block;
			filter: brightness(0) invert(1);
		}

		.phone,
		.mail {
			margin-bottom: 1.3rem;

			&::after {
				background-color: white;
			}
		}

		#menu-footer-container {
			margin-top: 3rem;

			ul {
				padding: 0;
				margin: 0;
				list-style: none;
				width: 100%;
				li {
					width: 100%;
					a {
						width: 100%;
						color: white;
						padding: 10px 5px;
						padding-right: 30px;
						border-bottom: 1px solid rgba(255, 255, 255, 0.5);
						display: inline-block;
						&:hover {
							color: darken(white, 6%);
						}
					}

					&:last-child {

						a {
							border-bottom: none;
						}
					}
				}
			}
		}
	}

	#footer-bottom {
		font-size: 0.8rem;
		border-top: 2px solid $color-primary;
		border-bottom: 2px solid $color-primary;
		padding: 10px 0;

		.container {
			display: flex;
			align-items: center;
			justify-content: space-between;

			@media (max-width: 1100px) {
				flex-flow: column;
				text-align: left;
				align-items: flex-start;
				line-height: 1.9;
				> *{
					display: block;
				}
			}

		}

		a {
			color: $color-text;

			&:hover {
				color: $color-link;
			}
		}
	}

	.powered {
		position: relative;
		color: inherit;
		&:hover {
			color: $color-text !important;
			text-decoration: none;
			#logo-alt-footer-trois {
				fill: #FFD94A;
				transform: scale(1.2);
			}
			#logo-alt-footer-deux {
				fill: #FFD94A;
				transform: scale(1.2);
			}
			#logo-alt-footer-un {
				fill: #FFD94A;
				transform: scale(1.2);
			}
			&:after {
				width: 90px;
			}
		}
		svg {
			overflow: visible;
			width: 50px;
			height: 24px;
			margin: 0 0 0 5px;
			position: relative;
			top: -2px;
			vertical-align: middle;
			width: 34px;
			#logo-alt-footer-trois {
				fill: #ffffff;
				transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s;
				transform: scale(1);
				transform-origin: center;
			}
			#logo-alt-footer-deux {
				fill: #ffffff;
				transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) 0.25s;
				transform: scale(1);
				transform-origin: center;
			}
			#logo-alt-footer-un {
				fill: #ffffff;
				transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) 0.4s;
				transform: scale(1);
				transform-origin: center;
			}
		}
		&:after {
			display: block;
			content: '';
			position: absolute;
			bottom: -2px;
			left: 0;
			width: 0px;
			height: 2px;
			background-color: #FFD94A;
			transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
		}
	}
}
