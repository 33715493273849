//
// Template Styles
// --------------------------------------------------

.d-tablet{
	&-none{
		@media (min-width: 993px) {
			display: none!important;
		}
	}
	&-inline-block{
		@media (min-width: 993px) {
			display: inline-block!important;
		}
	}
}

#main {
	padding-top: 156px; // Header height
	z-index: 15;
	position: relative;

	@media (max-width:992px) {
	    padding-top: 100px;
	}

	#page-header {
		max-width: 2500px;
		margin: 0 auto;
		margin-top: 3rem;

		h1 {
			margin: 0 auto;
			text-align: center;
			width: 100%;
		}
	}

	svg {
		&.green {
			z-index: 1;
			path,
			circle {
				opacity: 1;
				fill-opacity: 1;
				fill: $color-primary;
			}
		}

		&.green-light {
			//filter: blur(1px);
			z-index: -1;

			path,
			circle {
				fill: $color-green-light;
				opacity: 1;
				fill-opacity: 1;
			}
		}

		&.red {
			z-index: 1;
			path,
			circle {
				opacity: 1;
				fill-opacity: 1;
				fill: $color-red;
			}
		}

		&.red-light {
			//filter: blur(1px);
			z-index: -1;

			path,
			circle {
				fill: $color-red-light;
				opacity: 1;
				fill-opacity: 1;
			}
		}
	}

	.status {
		padding: 4px 10px;
		display: inline-block;
		width: fit-content;
		text-align: center;
		font-size: 0.8rem;
		color: white;
		border-radius: 3px;

		&.full {
			background-color: $color-gray-dark;
		}

		&.orange {
			background-color: $color-orange;
		}

		&.free {
			background-color: $color-primary;
		}
	}

	.courses-content {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		column-gap: 1.2rem;
		row-gap: 2.4rem;
		padding: 0;
		margin: 0;
		@media (max-width:992px) {
			display: flex;
			flex-flow: column;
			row-gap: 30px;
		}
	}

	.filters {
		ul {
			padding: 0;
			margin: 0;
			display: flex;
			align-items: center;
			justify-content: center;
			list-style: none;

			li {
				margin: 0 1.8rem;
				font-size: 1.3rem;
				font-weight: 500;
				@media (max-width:992px) {
					font-size: 17px;
					margin: 0 15px
				}

				a {
					font-size: 1.3rem !important;
					@media (max-width:992px) {
						font-size: 17px!important;
					}
				}

				&.is-active {
					color: $color-text;

					a {
						color: inherit;
						cursor: default;

						&::after {
							display: none;
							visibility: hidden;
						}
					}
				}
			}
		}
	}

	.reports {
		width: 100%;
		max-width: 50%;
		margin: 0 auto;
		margin-top: 10rem;
		position: relative;

		@media (max-width:992px) {
			margin-top: 136px;
			max-width: 100%;
		}

		svg {
			position: absolute;
			z-index: -1;
			top: -50%;
			left: 0;
			@media (max-width:992px) {
				max-width: 156px;
			    left: 0%;
    			top: -43%;
			}
		}

		h2 {
			text-align: center;
		}

		.content {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			column-gap: 2rem;
			row-gap: 2rem;
			list-style: none;
			margin: 0;

			@media (max-width:992px) {
				display: flex;
				flex-flow: column;
				justify-content: center;

				a{
					margin: 0 auto;
				}
			}

			.file {
				margin-bottom: 0;
			}
		}
	}

	#breadcrumb {

		color: #36b457;

		@media (max-width:1199px) {
			font-size: 13px;
		}

		a, .breadcrumb_last{
			color: $color-text;
		}

		a {
			position: relative;

			&::after {
				content: "";
				width: 100%;
				height: 2px;
				background-color: $color-primary;
				position: absolute;
				left: 0;
				bottom: -6px;
			}

			&:hover {
				color: $color-primary;
			}
		}
	}

	.info-box {
		box-shadow: 2px 2px 6px rgba(143, 143, 143, 0.24);
		border-radius: 4px;
		position: relative;
		max-width: 75%;

		@media (max-width:992px) {
			max-width: inherit;
		}

		&::before {
			content: "\f05a";
			font-family: 'Font Awesome 6 Pro';
			font-weight: bold;
			font-size: 2rem;
			position: absolute;
			width: 34px;
			height: 34px;
			@media (max-width:992px) {
				font-size: 24px;
				width: 24px;
				height: 24px;
			}
		}

		a {
			color: $color-text;
			text-decoration: underline;
		}

		&.yellow {
			border: 2px solid #FFD600;
			background-color: #FFFDF1;
			padding: 1.25rem;
			padding-left: 3.95rem;

			@media (max-width:992px) {
				padding: 15px 15px 15px 50px;
				p{
					font-size: 15px;
				}
			}

			&::before {
				color: #FFD600;
				left: 1.25rem;
				top: 1.75rem;
				@media (max-width:992px) {
					top: 13px;
					left: 13px;
				}
			}

			.title {
				display: block;
				margin-bottom: 1rem;
			}
		}

		&.green {
			border: 2px solid #D2E0D6;
			background-color: #F8FAF8;
			padding: 0.9rem 1.6rem;
			padding-left: 4.3rem;

			@media (max-width:1199px) {
				padding: 15px 15px 15px 50px;
				p{
					font-size: 15px;
				}
			}

			&::before {
				color: #D2E0D6;
				left: 1.6rem;
				top: 1.4rem;
				@media (max-width:1199px) {
					left: 13px;
					top: 13px;
				}
			}

			.title {
				font-weight: bold;
				display: block;
				margin-bottom: 0.25rem;
			}
		}
	}

	#page-courses,
	#single-course,
	#page-homepage {
		.filters {
			background-color: $color-primary;
			color: white;
			margin-top: $margin-base;
			padding: 1.25rem 2rem !important;
			display: flex;
			align-items: center;
			justify-content: flex-start;
			border: none !important;

			@media (max-width:992px) {
				margin-top: 55px;
				display: flex;
				flex-flow: column;
				align-items: flex-start;
				padding: 18px!important;
				width: 100%;
				margin-left: 0;
				margin-right: 0;
				ul{
					display: flex;
					flex-flow: column;
					align-items: flex-start;
					li{
						margin-left: 5px!important;
						margin-top: 12px;
					}
				}
			}

			.title {
				color: inherit;
				display: contents !important;
			}

			input {
				visibility: hidden;
				margin: 0;
				margin-right: 0;
			}

			label {
				padding-left: 5px;
				font-weight: 400;
				position: relative;
				cursor: pointer;
				left: initial;
				top: initial;
				transition: all 0.2s linear;
				font-size: 1rem;
				&::before {
					position: absolute;
					top: 50%;
					left: -20px;
					transform: translateY(-50%);
					content: "";
					width: 16px;
					height: 16px;
					border-radius: 50%;
					border: 2px solid white;
					background-color: transparent;
					transition: all 0.2s linear;
				}
			}

			li {
				margin: 0 1.25rem;
				&:first-child {
					margin-left: 3.75rem;
				}
				&:hover {
					label {
						&::before {
							background-color: white;
						}
					}
				}
			}

			input:checked ~ label {

				&::before {
					background-color: white !important;
				}
			}
		}
	}

	#page-courses {
		margin-top: 2rem;

		.info-box.yellow {
			margin: 0 auto;
		}

		.infos {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			column-gap: 2rem;
			margin-top: 1rem;
			@media (max-width:992px) {
				display: flex;
				flex-flow: column;
			}

			.btn-secondary.file {
				margin-top: 1rem;
			}
		}

		.courses {
			margin-top: 2.5rem;

			&:first-of-type {
				margin-top: 0;
			}

			h2 {
				margin: 0;
				margin-bottom: 2rem;
			}

			.courses-content {
				margin-top: 2.5rem;
				column-gap: 2rem;
			}
		}
	}

	#page-homepage {
		.filters {
			margin-top: 0;
		}
		.course-block {
			&.notrequired {
				display: none;
			}
		}
		.courses {
			margin-top: 2.5rem;

			h2 {
				margin: 0;
				margin-bottom: 2rem;
			}

			.courses-content {
				margin-top: 2.5rem;
				column-gap: 2rem;
			}
		}

		.intro {
			margin-top: 9rem;
			@media (max-width: 992px) {
				max-width: 567px;
				margin-left: auto;
				margin-right: auto;
				margin-top: 136px;
			}

			svg {
				&.circle {
					position: absolute;
					right: -2%;
    				top: 55%;
					z-index: -1;
					@media (max-width:992px) {
						bottom: calc(80px + 14%);
						top: inherit;
						right: -5%;
					}
				}
			}

			h2 {
				margin: 0;
				text-align: center;
			}

			.grid {
				display: grid;
				grid-template-columns: repeat(4, 1fr);
				column-gap: 3.125rem;
				row-gap: 3.125rem;
				margin-top: -1.7rem;

				@media (max-width: 992px) {
					display: flex;
					flex-wrap: wrap;
					justify-content: center;
					grid-column-gap: inherit;
					row-gap: inherit;
					grid-template-columns: inherit;
					margin-top: -12px;
				}

				a {
					display: block;
				}

				.block {

					@media (max-width: 992px) {
						max-width: calc(50% - 12px);
						margin: 0 auto 12px auto;

						&:nth-child(odd){
							margin-left: 0;
						}

						&:nth-child(even){
							margin-right: 0;
						}

					}

					img {
						aspect-ratio: 1;
					}

					h3 {
						transition: $transition-base;
						@media (max-width: 992px) {
							margin-top: 7px;
						}
					}

					&:hover {

						h3 {
							color: $color-link;
						}
					}
				}
			}

			.btn-primary {
				display: block;
				margin: 0 auto;
				margin-top: 1.6rem;
				@media (max-width: 992px) {
					width: 100%;
					margin: 0;
				}
			}
		}

		.discover {
			margin-top: 9.4rem;
			position: relative;

			@media (max-width:992px) {
				margin-top: 110px;


				.slick-dots{
					display: none!important;
				}
			}



			svg {
				&.ellipse.red {
					position: absolute;
					left: 2%;
					top: 0%;
					@media (max-width:992px) {
						display: none;
					}
				}
			}

			h2 {
				margin: 0;
				text-align: center;
			}

			.video-slider {
				margin-top: -1.7rem;

				@media (max-width:992px) {
					margin-top: -12px;
				}

				.slick-slide {
					opacity: .4;
					margin: 0 1.6rem;
					transition: $transition-base;
					width: 70vw;
					height: auto;
					position: relative;
					min-height: 76px;

					@media (max-width:992px) {
						margin: 0 5px;
					}

					svg {
						&.circle {
							position: absolute;
							z-index: -1;
							left: 50%;
							transform: translateX(-50%);
							top: 70%;
							@media (max-width:992px) {
								bottom: 0;
								top: inherit;
							}
						}
					}

						&.slick-active {
							opacity: 1;
						}

						video {
							width: 100%;
							height: auto;
							aspect-ratio: 1.78 / 1;
						}

						h3, .h3, h4, .h4{
							text-align: center;
							margin: 0;
						}

						h3, .h3, .h4{
							margin-bottom: 0.4rem;
							margin-top: 1rem;
							@media (max-width:992px) {
								margin-top: 7px;
							}
						}

						h4, .h6{
							@media (max-width: 992px) {
								margin-bottom: 17px;
							}
						}
				}
			}
		}

		.news {
			margin-top: 12rem;
			@media (max-width: 992px) {
				max-width: 576px;
				margin: 117px auto 0 auto;


				.btn-primary{
					width: auto;
					display: block;
					margin: 30px 28px 0 0;
					text-align: center;
				}

				.slick-dots{
					display: none!important;
				}
			}

			@media (max-width: 576px) {
				margin-left: 15px;
				.btn-primary{
					margin: 15px 15px 0 0;
				}
			}

			svg {
				&.circle {
					position: absolute;
					left: -10%;
    				top: -90%;
					@media (max-width:992px) {
						width: 46px;
						height: auto;
						left: -5.8%;
						top: -110%;
						aspect-ratio: 46/106;
					}
				}
			}

			h2:not(.h6.title) {
				margin: 0;
				color: $color-green-light;
			}

			.container {
				display: flex;
				align-items: center;
				justify-content: space-between;
				@media (max-width: 992px) {
					padding: 0;
				}
			}

			.news-content {
				width: 100%;
				max-width: 97%;
				margin-left: calc(3% + 15px);
				margin-top: -1.7rem;
				overflow: hidden;

				@media (max-width: 992px) {
					margin-left: 0;
					margin-top: -12px;
				}

				.news-slider {
					margin-left: 0;
					margin-bottom: 0!important;

					.slick-list {
						overflow: visible !important;
					}

					.slick-slide {
						padding-right: 30px;
						max-width: 22rem;
						// opacity: .4;
						opacity: 1;
						transition: $transition-base;
						@media (max-width:992px) {
							padding-right: 20px;
							max-width: 294px;
							width: 100%;
						}

						// &.slick-active {
						// 	opacity: 1;
						// }
					}
				}
			}
		}

		.next-courses {
			margin-top: $margin-base;
			@media (max-width:992px) {
				max-width: 576px;
				margin: 93px auto 0 auto;
			}

			svg {
				&.ellipse,
				&.circle {
					position: absolute;
					z-index: -1;
				}

				&.circle {
					right: -2%;
					top: 8%;
					@media (max-width:992px) {
						aspect-ratio: 1/1;
						max-width: 61px;
						height: auto;
						top: 55px;
						right: -1px;
					}
				}

				&.ellipse {
					left: -13%;
					bottom: 22%;
					@media (max-width:992px) {
						display: none;
					}
				}
			}

			// h2 {
			// 	margin: 0;
			// 	@media (max-width:992px) {
			// 		max-width:244px;
			// 	}
			// }
			//
			// .courses-content {
			// 	margin-top: -2rem;
			// 	//grid-template-columns: repeat(3,minmax(min-content, 370px));
			// 	@media (max-width: 992px) {
			// 		margin-top: -12px;
			// 	}
			// }
		}

		.partners {
			margin-top: $margin-base;
			@media (max-width:992px) {
				max-width: 576px;
				margin: 77px auto 0 auto;
			}

			svg {
				&.ellipse.red {
					position: absolute;
					right: -5%;
					top: -35%;
					@media (max-width:992px) {
						display: none;
					}
				}
			}

			h2 {
				margin-bottom: 1.3rem;
				text-align: center;
			}

			.partners-slider {

				.slick-list {
					margin: 0 3.2rem;
				}

				.slick-track {
					display: flex;
					align-items: center;
				}

				.slick-slide {
					margin: 0 1.4rem;
					img{
						max-width: 110px;
						max-height: 86px;
					}
				}
			}
		}

	}

	#single-news {

		svg {

			&.ellipse {
				position: absolute;
				right: -14%;
				bottom: -36%;
				@media (max-width:1199px) {
					max-width: 130px;
					height: auto;
					bottom: -150px;
    				right: -50px;
				}
			}

			&.circle {
				position: absolute;
				right: 5%;
				bottom: -20%;
				@media (max-width:1199px) {
					max-width: 43px;
					height: auto;
					right: 90px;
					bottom: -115px;
				}
			}
		}

		#post-content {
			max-width: 66%;
			margin: 0 auto;
			position: relative;
			@media (max-width: 1199px) {
				max-width: 100%;
			}

			.date {
				font-size: 0.875rem;
				color: #a8a8a8;
				margin-bottom: 1.25rem;
				display: inline-block;
				text-transform: uppercase;
			}

			#post-share {
				margin-top: 2.5rem;
				display: flex;
				align-items: center;
				justify-content: flex-start;

				.title {
					display: inline-block;
					margin-right: 1.5rem;
				}

				.social-share {

					li {
						margin-right: 1rem;

						a {

							i {
								font-size: 1.9rem;
							}
						}
					}
				}
			}
		}

		#related-posts {
			width: 100%;
			max-width: 97%;
			margin-left: calc(3% + 15px);
			overflow: hidden;
			margin-top: $margin-base;

			@media (max-width:1199px) {
				max-width: 576px;
				margin-top: 189px;
				margin: 189px auto 0 auto;
				padding: 0 0 0 28px;
			}

			h2:not(.h6.title) {
				margin: 0;
				color: $color-green-light;
				text-align: center;
			}

			.news-slider {
				margin-top: -1.7rem;
				@media (max-width:1199px) {
					margin-top: -12px;
				}

				.slick-list {
					overflow: visible !important;
				}

				.slick-slide {
					padding-right: 30px;
					max-width: 22rem;
					opacity: .4;
					transition: $transition-base;

					@media (max-width:1199px) {
						max-width: inherit;
					}

					&.slick-active {
						opacity: 1;
					}
				}
			}
		}
	}

	#page-blog,
	#page-news-taxonomy {

		.filters {
			margin-top: 4rem;
			@media (max-width:992px) {
				margin-top: 27px;

			}
		}

		#news {
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			column-gap: 1.875rem;
			row-gap: 2.1875rem;
			margin-top: $margin-base;
			@media (max-width:992px) {
				margin-top: 46px;
				display: flex;
				flex-flow: column;
			}
		}
	}

	#page-organization {
		.organization {
			.category {
				margin-top: 5.8rem;
				position: relative;

				svg {
					position: absolute;
					z-index: -1;
					&.ellipse {
						&.green-light {
							top: -12%;
							left: -6%;
						}
					}

					&.circle {
						&.red-light {
							top: 50%;
							right: -6%;
							@media (max-width: 992px) {
								display: none;
							}
						}
					}
				}

				&:first-of-type {
					margin-top: $margin-base;
				}

				h2 {
					margin-bottom: 1.18rem;
				}

				.description {
					max-width: 75%;
					@media (max-width:992px) {
						max-width: 100%;
					}
				}

				.members {
					display: grid;
					grid-template-columns: repeat(4, 1fr);
					column-gap: 1.8rem;
					row-gap: 3.5rem;
					margin-top: 2.4rem;

					@media (max-width:992px) {
						display: flex;
						flex-wrap: wrap;
						column-gap: 0;
						row-gap: 24px;
						> div{
							max-width: calc(50% - 12.5px);
							&:nth-child(even){
								margin-left: 12.5px;
							}
							&:nth-child(odd){
								margin-right: 12.5px;
							}

							.informations{
								display: flex;
								> a{
									&:first-of-type{
										margin-right: 23px;
									}
								}
							}

							.phone, .mail{
								width: 22px;
								span{
									display: none;
								}
							}
						}
					}
				}
			}
		}
	}

	#page-text-column {
		#post-content {
			.text {
				margin-top: 5.9rem;

				svg {
					&.green {
						top: 18%;
						right: -12%;
						@media (max-width:992px) {
							display: none;
						}
					}

					&.green-light {
						left: -7%;
						top: -3%;
						@media (max-width:992px) {
							max-width: 122px;
							height: auto;
							top: -7%;
							left: -11%;
						}
					}
				}

				h3 {
					margin-bottom: 1.18rem;
				}

				h4 {
					max-width: 80%;
					margin-bottom: 1.7rem;
					margin-top: 0;
					@media (max-width:992px) {
						max-width: 100%;
					}
				}

				.grid {
					display: grid;
					grid-template-columns: repeat(2, 1fr);
					column-gap: 1.8rem;

					@media (max-width:992px) {
						display: block;
					}

					p {
						margin-top: 0;
					}
				}

				blockquote {
					border: none;
					padding: 0 2rem;
					margin: 0;
					position: relative;

					@media (max-width:992px) {
						padding: 26px 0;
						margin-bottom: 18px;
					}

					&::before {
						content: "\f10d";
						font-family: "Font Awesome 6 Pro";
						font-weight: bold;
						color: $color-primary;
						font-size: 1.2rem;
						position: absolute;
						left: 0;
						top: -2%;
						@media (max-width:992px) {
							top: 0;
						}
					}

					&::after {
						content: "\f10e";
						font-family: "Font Awesome 6 Pro";
						font-weight: bold;
						color: $color-primary;
						font-size: 1.2rem;
						position: absolute;
						right: 0;
						bottom: -2%;
						@media (max-width:992px) {
							bottom: 0;
						}
					}
				}
			}

			.img-content {
				margin-top: 4.2rem;

				svg {
					&.red {
						top: -9%;
						right: -6%;
					}

					&.green-light {
						left: 12%;
						bottom: -4%;
						@media (max-width:992px) {
							max-width: 112px;
							height: auto;
							bottom: -14%;
							left: 7%;
						}
					}

					&.red-light {
						top: -6%;
						left: -8%;
						@media (max-width:992px) {
							max-width: 132px;
							height: auto;
							top: -17%;
							right: -15px;
						}
					}
				}
			}

			.text,
			.img-content {
				position: relative;

				svg {
					position: absolute;
					z-index: -1;
				}
			}
		}
	}

	#page-alternate-block {
		.blocks-container {
			max-width: 2500px !important;
			padding: 0 !important;
		}

		.form-content {
			position: relative;
			width: 100%;
			max-width: 66%;
			margin: 0 auto;
			margin-top: 11rem;
			@media (max-width: 1199px) {
				max-width: 100%;
				margin-top: 9rem;
			}
			@media (max-width: 768px) {
				margin-top: 7rem;
			}

			&.full {
				max-width: 100%;
			}

			h2 {
				text-align: center;
			}

			.gform_footer {
				margin-top: 60px;
				justify-content: center;
			}

			svg.shape {
				position: absolute;
				z-index: -1;
				top: 6%;
				left: 50%;
				transform: translateX(-50%);
				width: 75%;
				height: auto;
			}

			.membership-choices {
				display: flex;
				justify-content: space-between;
				gap: 2rem;
				margin-top: 5rem;
				@media (max-width: 768px) {
					flex-flow: column;
					margin-top: 3rem;
				}
				.title {
					margin-bottom: 1.8rem;
				}
				.actions {
					display: flex;
					margin-top: 1.5rem;
				}
			}
		}
	}

	#page-default {
		#post-content {
			width: 100%;
			max-width: 66%;
			margin: 0 auto;
			margin-top: 2.5rem;
			@media (max-width: 1199px) {
				max-width: 100%;
			}

			.has-text-align-right {
				> * {
					margin-left: auto;
				}
			}
		}
	}

	#single-course {

		.course-block {
			&.notrequired {
				display: none;
			}
		}

		#infos {
			margin-top: $margin-base;
		}

		.courses {
			margin-top: 2.5rem;

			h2 {
				margin: 0;
				margin-bottom: 2rem;
			}
			
			.category-description {
				font-size: 20px;
			}

			.courses-content {
				margin-top: 2.5rem;
				column-gap: 2rem;
			}
		}

		.grid-infos {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			gap: 2rem;
			margin-top: $margin-base;

			@media (max-width:1199px) {
				display: flex;
				flex-flow: column;
			}

			.info-box {
				max-width: 100%;
			}
		}

		.top-page {
			// display: grid;
			// grid-template-columns: 66% 34%;
			// gap: 3.2rem;
			position: relative;

			@media (max-width:1199px) {
				display: block;
				grid-template-columns: inherit;
				gap: 0;
			}

			svg {
				&.ellipse.green-light {
					position: absolute;
					top: -45%;
					left: -15%;
				}
			}

			aside {
				width: 100%;
				max-width: calc(100% - 2rem);
				position: relative;

				svg {
					&.ellipse.red-light {
						position: absolute;
						bottom: -12%;
    					right: -30%;
						@media (max-width:1199px) {
							display: none;
						}
					}
				}

				section {
					margin-bottom: 1.7rem;

					h3 {
						margin: 0;
						margin-bottom: 1.25rem;

						i {
							margin-right: 0.7rem;
						}
					}

					&.price {
						display: flex;
						align-items: center;
						justify-content: flex-start;
						h3 {
							margin-bottom: 0;
							margin-right: 0.6rem;
						}
					}
				}

				.files {

					.file {
						margin-bottom: 1.7rem;
					}
				}
			}
		}

		.units-accordion {
			margin-top: 6.25rem;
			position: relative;

			svg {
				&.ellipse.green {
					position: absolute;
					bottom: 18%;
    				left: -22%;
					@media (max-width:1199px) {
						display: none;
					}
				}
			}

			.content {
				border-bottom: 3px solid $color-green-light;
				margin-bottom: 0.6rem;

				&:last-child {
					margin-bottom: 0;
				}

				.tab-title {
					cursor: pointer;
					display: flex;
					align-items: center;
					justify-content: space-between;
					padding: 1.8rem 0.3rem;
					margin: 0;
					position: relative;
					color: $color-text;

					&::before {
						display: none;
						visibility: hidden;
					}

					i {
						transition: $transition-base;
						color: $color-primary;
						font-size: 1.4rem;
						transform: rotateZ(0deg);
					}

					&.is-open {

						i {
							transform: rotateZ(180deg);
						}
					}
				}

				.tab-info {
					display: none;
					padding: 1.8rem 0.3rem;
					padding-top: 0;

					.post-content {
						margin-bottom: 35px;
					}

					ul {
						padding: 0;
						margin: 0;
						list-style-position: inside;
						max-width: 80%;

						li {
							display: flex;
							align-items: center;
							justify-content: space-between;
							position: relative;
							padding-left: 1.4rem;
							margin-bottom: 0.4rem;

							&::before {
								content:"\f111";
								font-family: "Font Awesome 6 Pro";
								font-weight: bold;
								color: inherit;
								position: absolute;
								left: 0;
								top: 0.1rem;
								font-size: 0.3rem;
							}

							span {
								color: #858585;
							}
						}
					}
					.duration,
					.price,
					.files {
						margin-top: 3rem;
						margin-right: 2rem;
						display: inline-block;

						h4 {
							font-size: 0.8rem;
							color: $color-gray;
							font-weight: 400;
							text-transform: uppercase;
							letter-spacing: 0.02rem;
							margin-bottom: 0.4rem;
						}
					}

					.files {
						display: grid;
						grid-template-columns: repeat(3, minmax(auto, 290px));
						column-gap: 1rem;
						row-gap: 2rem;
						margin-right: 0;
						margin-top: 2rem;
					}
				}
			}
		}

		.contact {
			margin-top: $margin-base;
			@media (max-width:1199px) {
				margin-top: 80px;
			}

			.grid-contact {
				display: grid;
				grid-template-columns: repeat(3, 1fr);
				column-gap: 6.25rem;
				row-gap: 5rem;
				@media (max-width:1199px) {
					display: flex;
					flex-flow: column;
				}
			}

			section {

				.title {
					font-weight: 700;
					display: inline-block;
					margin-bottom: 1.25rem;
				}

				.name {
					display: inline-block;
					margin-bottom: 1rem;
				}

				.phone {
					margin-bottom: 1.4rem;
				}
			}
		}

		#sessions {
			margin: $margin-base 0;
			position: relative;

			svg {
				&.ellipse.green-light {
					position: absolute;
					bottom: -75%;
					right: -14%;
					@media (max-width:1199px) {
						max-width: 83px;
						height: auto;
						right: -10%;
					}
				}
			}

			h2 {
				margin-bottom: 2rem;
			}

			ul {
				padding: 0;
				margin: 0;
				height: auto;
				max-height: 255px;
				overflow: hidden;
				transition: all .2s linear;

				&.is-full {
					max-height: fit-content;
				}
			}

			.btn-more {
				margin-top: 25px;
				font-size: 1rem;
			}
		}

		#units {
			margin: $margin-base 0;
			position: relative;

			@media (max-width:1199px) {
				margin: 80px 0;
			}

			svg {
				&.ellipse,
				&.circle {
					position: absolute;
				}

				&.ellipse.green {
					top: 4%;
    			left: -2%;
				}

				&.circle.red-light {
					top: 7%;
    			left: 11%;
				}

				&.ellipse.red-light {
					right: -1%;
    			bottom: 2%;
				}
			}

			h2 {
				text-align: center;
				margin-bottom: 0;
				@media (max-width:1199px) {
					max-width: 314px;
					margin: 0 auto;
				}
			}

			.info-box {
				margin: 0 auto;
				margin-top: 1.25rem;
			}

			.content {
				border: 4px dashed $color-green-light;
				padding: 60px 20px;
				padding-bottom: 40px;
				border-radius: 4px;
				position: relative;
				margin-top: 70px;

				.title {
					position: absolute;
					top: -21px;
					left: 20px;
					font-size: 0.9rem;
					font-weight: 500;
					padding: 10px 30px;
					text-align: center;
					background-color: $color-green-light;
				}
			}

			.btn-content {
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				margin-top: 60px;

				.btn-primary {
					margin: 0 15px;
				}
			}
		}
	}

	#page-contact {
		.contact {
			display: flex;
			align-items: center;
			justify-content: center;
			margin-top: 2.4rem;
			margin-bottom: $margin-base;

			@media (max-width:992px) {
				flex-flow: column;
			}

			a {
				margin: 0 1rem;
				font-size: 1.18rem;
				@media (max-width:992px) {
					margin-bottom: 30px;
				}
			}
		}

		.row {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			column-gap: 5.6rem;

			@media (max-width:992px) {
				display: flex;
				flex-flow: column;
				column-gap: inherit;
				padding-left: 28px;
				padding-right: 28px;
				margin-bottom: 90px;
			}

			.infos{
				@media (max-width:992px) {
					margin-bottom: 17px;
					.accordion{
						.content{
							&:last-of-type{
								border-bottom: 0;
							}
						}
					}
				}
			}

			#contact-map {
				width: 100%;
				display: flex;
				align-items: flex-start;
				justify-content: space-between;
				flex-wrap: wrap;

				.acf-map {
					width: 100%;
					height: auto;
					aspect-ratio: 1;
				}

				address {
					white-space: nowrap;
					margin-top: 2.6rem;
				}

				.btn-secondary {
					font-size: 1.18rem;
					margin-top: 3.4rem;
				}
			}
		}
	}

	#page-faq,
	#page-faq-taxonomy {
		.filters {
			margin-top: 4rem;
			@media (max-width: 992px) {
				margin-top: 25px;
			}
		}

		.accordion {
			margin-top: $margin-base;
			@media (max-width: 992px) {
				margin-top: 25px;

				.content{
					&:last-of-type{
						border-bottom: 0;
					}
				}
			}

		}
	}
}

.header-mobile{
	> ul, &-top{
		display: none;
	}
}

#header.dark{
	.header-mobile-open{
		span{
			background: #fff;
		}
	}
	&.sticky{
		.header-mobile-open{
			span{
				background: #36b457;
			}
		}
	}
}


@media (max-width: 1199px) {

	#menu-mobile-trigger{
		display: none!important;
	}

	#header{
		z-index: 9999;

		#logo{
			max-width: 238px!important;
		}

		.header-mobile{
			background: #36b457;
			position: fixed;
			top: 0;
			bottom: 0;
			right: 0;
			max-width: 100%;
			width: 100%;
			color: #fff;
			font-size: 20px;
			line-height: 1.1;
			z-index: 9999;
			overflow-y: auto;
			display: none;
			flex-flow: column;

			&.active{
				display:flex!important;
			}

			&-top{
				padding: 58px 26px 40px 26px;
				display: flex;
				align-items: center;
			}

			&-open{
				display: flex;
				flex-flow: column;
				width: 10px;
				position: absolute;
				right: 27px;
				top: 0;
				cursor: pointer;
				span{
					width: 10px;
					height: 10px;
					background: #36b457;
					border-radius: 50%;
					&:not(:last-of-type){
						margin-bottom: 5px;
					}
				}
			}

			&-close{
				position: relative;
				width: 25px;
				height: 25px;
				margin: auto 0 auto auto;
				cursor: pointer;
				&::before, &::after{
					content: " ";
					position: absolute;
					left: 11px;
					content: ' ';
					height: 25px;
					width: 3px;
					background: #fff;
					border-radius: 1rem;
				}

				&::before{
					transform: rotate(45deg);
				}

				&::after{
					transform: rotate(-45deg);
				}
			}

			ul{
				list-style: none;
				padding: 0;
				margin: 0;
				display: block;

				&.actived-only{
					> li{
						&:not(.active){
							display: none;
						}
					}
				}

				li{
					a{
						display: block;
						padding: 31px 32px;
						color: #fff;
					}

					&:not(:first-of-type), &:not(:last-of-type){
						border-top: 1px solid #fff;
					}

					&.menu-item-has-children{
						> a{
							position: relative;
							&::after, &::before{
								content: " ";
								position: absolute;
								top: 0;
								bottom: 0;
								margin: auto 0;
								width: 9px;
								height: 16px;
								background-image: url(../images/arrow-icon.svg);
								background-size: contain;
								background-repeat: no-repeat;
								background-position: center;
							}

							&::before{
								left: 26px;
								opacity: 0;
							}

							&::after{
								right: 26px;
								transform: rotate(180deg);
								opacity: 0;
							}
						}

						&.current-menu-ancestor{
							&.active{
								> a{
									padding-left: 60px;
									&::before{
										opacity: 1;
									}
								}
							}
						}

						&:not(.current-menu-ancestor){
							> a{
								padding-right: 60px;
								&::after{
									opacity: 1;
								}
							}
						}

						&:not(.active){
							ul{
								display: none;
							}
							> a{
								padding-right: 60px;
								&::after{
									opacity: 1;
								}
							}

						}

						&.active{
							> a{
								padding-left: 60px;
								&::before{
									opacity: 1;
								}
								&::after{
									opacity: 0!important;
								}
							}
						}
					}

				}

			}

			#header-extras{
				position: unset;
				top: inherit;
				right: inherit;
				background: #fff;
				justify-content: flex-start;
				padding: 0px 32px;
				opacity: 1!important;
				margin-top: 33px!important;

				> div{
					border-color: #36B457;
				}

				a{
					background-color: #fff;
					color: #36B457;
					&:hover, &:focus, &:active{
						background-color: #fff;
					}
				}
			}


		}
	}
}


.page-template-template-contact{
	#page-header.standard .shapes .ellipse.green{
		@media (max-width:992px) {
			top: 117%;
		}
	}
}

.page-template-template-blog, .archive{
	#page-header.standard .shapes .ellipse.green{
		@media (max-width:992px) {
			top: 337%;
			right: -54px;
			z-index: 10;
		}
	}
}


.single.single-news{
	@media (max-width:1199px) {
		#page-header{
			.container{
				padding: 0;
			}
		}
		#main{
			max-width: 576px;
			margin: 0 auto;
		}
	}
}

@media (max-width:992px) {

	.registration-table{
		table{
			display: block;

			thead{
				display: none;
			}

			tbody{
				display: block;
			}
		}

		.session{
			display: flex;
			flex-flow: column;
			font-size: 14px;
			position: relative;
			margin-bottom: 20px!important;
			td{
				display: flex;
				padding-left: 42px!important;
				padding-right: 5px!important;
				align-items: center;
				border-bottom: #DDDDDD 1px solid;
				border-top: 0!important;
				text-align: left;
				padding-top: 5px!important;
				padding-bottom: 5px!important;

				&:first-child{
					padding: 0!important;
				}

				&:last-child{
					padding-bottom: 25px!important;
				}

				&:not(:last-child){
					border: 0!important;
				}

				&::before{
					content: attr(data-title);
					color: #A8A8A8;
					display: block;
					text-transform: uppercase;
					max-width: 122px;
					padding-right: 10px;
					width: 100%;
				}

				&.input{
					padding: 0!important;
					position: absolute;
					left: -5px;
					top: 4px;
					max-width: 25px;

				}
			}
		}

	}
}
